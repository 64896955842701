@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "the-sans";
  src: url("./assets/fonts/TheSans-Bold.otf") format("truetype");
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "the-sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

.child {
  position: absolute;
}

.child:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.child:nth-child(2) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
.child:nth-child(3) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}
.child:nth-child(4) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}
.child:nth-child(5) {
  top: 25%;
  right: 25%;
  transform: translate(85%, -90%);
}
.child:nth-child(6) {
  bottom: 25%;
  right: 25%;
  transform: translate(85%, 90%);
}
.child:nth-child(7) {
  top: 25%;
  left: 25%;
  transform: translate(-90%, -85%);
}
.child:nth-child(8) {
  bottom: 25%;
  left: 25%;
  transform: translate(-85%, 90%);
}

.blob {
  background: black;
  border-radius: 50%;
  margin: 10px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.slick-slider.slick-initialized button {
  z-index: 10 !important;
}

.bubble {
  --r: 25px; /* the radius */
  --t: 30px; /* the size of the tail */
  /* max-width: 300px; */
  width: fit-content;
  /* padding: calc(2 * var(--r) / 3); */
  -webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%)
      var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
    conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)
      calc(var(--r) / -2) padding-box,
    radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space
      padding-box;
  background: linear-gradient(135deg, #5d2a02, #05a17a) border-box;
  color: #fff;
  box-sizing: content-box !important;
}

.left {
  --_d: 0%;
  border-left: var(--t) solid #0000;
  /* margin-right: var(--t); */
  /* place-self: start; */
}
.right {
  --_d: 100%;
  border-right: var(--t) solid #0000;
  /* margin-left: var(--t); */
  /* place-self: end; */
}
